import React, {useState} from "react";
import SortByAlpha from "@material-ui/icons/SortByAlpha";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import "./style.styl";

export const sortValueToNameMapping = {};

const optionsListDefault = [
    { name: "Name", value: "name" },
    { name: "Newest", value: "created_at_desc" },
    { name: "Oldest", value: "created_at_asc" },
];


optionsListDefault.forEach((item, i) => {
    sortValueToNameMapping[item.value] = item.name;
});



export default function SortWidget(props) {
    const {tooltip, button, mode, iconButtonClassName, onSelect} = props;
    const [open, setOpen] = useState(null);
    const selected = props.selected || "name";
    const optionsList = props.optionsList || optionsListDefault;
    const button_ = (
        button ?
            <div onClick={(event) => setOpen(event.currentTarget)}>
                {button}
            </div>
            :
            (
                mode === "button" ?
                    <Button className="u-opacity80 u-marginRight8" variant="outlined" size="small" onClick={(event) => setOpen(event.currentTarget)}>
                        {optionsList.filter(v => v.value === selected)[0].name}
                    </Button>
                    :
                    <IconButton className={(iconButtonClassName || "")} onClick={(event) => setOpen(event.currentTarget)}>
                        <SortByAlpha/>
                    </IconButton>
            )
    );

    const buttonWithTooltip = (
        tooltip && mode !== "button" ?
            <Tooltip title={tooltip}>
                {button_}
            </Tooltip>
            :
            button_
    );

    return (
        <React.Fragment>
            {buttonWithTooltip}
            <Menu
                anchorEl={open}
                open={Boolean(open)}
                onClose={() => setOpen(null)}
            >
                {
                    optionsList.map((item, i) => {
                        const isSelected = selected === item.value;
                        return (
                            <MenuItem key={i} selected={isSelected} onClick={()=>{onSelect(item.value); setOpen(null);}}>
                                {item.name}
                            </MenuItem>
                        );
                    })
                }
            </Menu>
        </React.Fragment>
    );
}
